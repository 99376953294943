import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Routes from './config/routes';

class App extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Routes {...this.props} />
    )
  }
}

export default App;
