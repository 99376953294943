import apiRequest from '.';
import APIUtils from './APIUtils';
import { auth, db, analytics, dbstore } from "../services/firebase";

class MatchAPI {

    startMatch(id, cb) {
        apiRequest({ url: `https://us-central1-tmlive-42e82.cloudfunctions.net/startMatch?id=${id}`, method: 'GET' }, cb);
    }

}

export default new MatchAPI();