import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';
import { logout } from '../../helpers/auth';
import { setUi } from '../../actions/ui';
import { auth, db, analytics, dbstore } from "../../services/firebase";
import ClubAPI from '../../api/ClubAPI';
import MatchAPI from '../../api/MatchAPI';

class MatchLiveScreen extends React.Component {

    constructor(props) {
        super(props);

        //this.canvasRef = React.createRef();

        this.state = {
          user: auth().currentUser,
          kickOff: false,
          events: [],
          minute: 0,
        };

        this.kickOff = this.kickOff.bind(this);
        this.myRef = React.createRef();

    }

    componentDidMount() {
      const pusher = new Pusher('ee75a9928f34ebc685a0', {
        cluster: 'eu',
        encrypted: true
      });
      const channel = pusher.subscribe(this.props.match_id);
      channel.bind('event', data => {
        console.log(data);
        this.setState({
          events: [...this.state.events, data],
          minute: data.minute,
        });

      });

      //this.handleTextChange = this.handleTextChange.bind(this);
    }
  

    /*
    async componentDidMount() {
    
        console.log("run MatchLive componentDidMount");
        try {
          db.ref("match").on("value", snapshot => {
            let events = [];
            snapshot.forEach((snap) => {
              events.push(snap.val());
            });
            events.sort(function (a, b) { return a.timestamp - b.timestamp })
            console.log("events:", events);
            this.setState({ events });
          });
        } catch (error) {
            console.log("error", error.message);
          this.setState({ readError: error.message, loadingChats: false });
        }
      }

      */
    
    /*
  componentDidMount() {
    //const canvas = this.canvasRef.current;
    //const context = canvas.getContext('2d');
    //context.fillRect(0, 0, canvas.width, canvas.height);
  }
  */

  async kickOff(event) {

    var _self = this;

    event.preventDefault();

    dbstore.collection("matches").add({
      minute: 0,
      accepted: true,
      completed: false,
      started: false,
    })
    .then(function(ref) {
      
      console.log("Match created.", ref);
      _self.setState({
        matchId: ref.id
      });

    })
    .catch(function(error) {
        console.error("Error writing match document: ", error);
    });

      /*event.preventDefault();
      MatchAPI.startMatch(this.state.matchId, (data) => {
        this.setState({
            kickOff: true
        });
      });*/
  }

  render() {
    return (
        <div className="screen">
            <div className="panel full">
                <div className="panel-header">
                    <h2>Match Live</h2>
                </div>
                <div className="panel-body">

                    <div className="match" ref={this.myRef}>

                    {(!this.state.kickOff && 1==2) &&
                    <form style={{position: 'absolute', zIndex: 99, top: 15, left: 15}} onSubmit={this.kickOff}>
                        <button className="btn btn-primary px-5" type="submit">Kick Off</button>
                    </form>
                    }

                    <div style={{fontSize: 30, color: '#fff'}}>Minute: {this.state.minute}</div>
                    <div style={{color: '#fff'}}>Match ID: {this.props.match_id}</div>

                    <div style={{color: '#fff'}}>
                        <div>Event items go here...</div>
                        {this.state.events.map((item, index) => 
                        <div key={index}>{item.name}: {item.text}</div>
                        )}
                    </div>

                    </div>

                    {/*
                    <div className="match">
                        <canvas ref={this.canvasRef} />
                    </div>
                    */}

                </div>
            </div>
        </div>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,
    match_id: state.ui.match_id,
  }
};

export default connect(mapStateToProps)(MatchLiveScreen);