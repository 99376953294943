import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import SplashPage from '../pages/Splash';
import Game from '../pages/Game';
import Signup from '../pages/Signup';
import Login from '../pages/Login';
import Billing from '../pages/Billing';
import Setup from '../pages/Setup';
import { auth } from '../services/firebase';
import Loader from '../components/Loader';

class Routes extends React.Component {

    constructor(props) {
      super(props);
        this.state = {
            authenticated: false,
            loading: true
        };
    }
    
    componentDidMount() {
        auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    authenticated: true,
                    loading: false
                });
            } else {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            }
        });
    }

    render() {

        const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
            <Route
                {...rest}
                render={props =>
                authenticated === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{ pathname: "/splash", state: { from: props.location } }}
                    />
                    )
                }
            />
        );
        
        const PublicRoute = ({ component: Component, authenticated, ...rest }) => (
            <Route
                {...rest}
                render={props =>
                authenticated === false ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                    )
                }
            />
        );

        return this.state.loading === true ? (
            <Loader />
        ) : (
            <div className={this.state.authenticated ? 'client' : 'frontend'}>
            <Router>
                <Switch>
                    <PrivateRoute exact path="/" authenticated={this.state.authenticated} component={Game} />
                    <PrivateRoute path="/billing" authenticated={this.state.authenticated} component={Billing} />
                    <PrivateRoute path="/setup" authenticated={this.state.authenticated} component={Setup} />
                    <PublicRoute path="/splash" authenticated={this.state.authenticated} component={SplashPage} />
                    <PublicRoute path="/signup" authenticated={this.state.authenticated} component={Signup} />
                    <PublicRoute path="/login" authenticated={this.state.authenticated} component={Login} />
                </Switch>
            </Router>
            </div>
        );
    }

};

export default Routes;