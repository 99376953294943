import people from './people-reducer.js';
import ui from './ui';
import user from './user';
import {combineReducers} from 'redux';

const rootReducer = combineReducers({
  people,
  ui,
  user,
});

export default rootReducer;