import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../helpers/auth';
import { setUi } from '../../actions/ui';
import { auth, db, analytics, dbstore } from "../../services/firebase";
import ClubAPI from '../../api/ClubAPI';

class ClubScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          clubs: []
        };
        this.challenge = this.challenge.bind(this);
    }

  componentDidMount() {
      this.loadClubs();
  }

  loadClubs() {
    ClubAPI.getClubs((data) => {
        this.setState({
            clubs: data
        });
    });
  }

  async challenge(event) {

    var _self = this;

    event.preventDefault();

    dbstore.collection("matches").add({
      minute: 0,
      clubs: [_self.props.user_id, _self.props.club_id],
      home_id: _self.props.user_id,
      away_id: _self.props.club_id,
      accepted: true,
      completed: false,
      started: false,
    })
    .then(function(ref) {
      
      console.log("Match created.", ref);
      let payload = {
        match_id: ref.id,
      }
      _self.props.dispatch(setUi(payload));

    })
    .catch(function(error) {
        console.error("Error writing match document: ", error);
    });

  }

  render() {
    return (
        <div className="screen">
            <div className="row">

                <div className="col-sm-6">
                    <div className="panel">
                    <div className="panel-header">
                        <h2>Club Details</h2>
                    </div>
                    <div className="panel-body">
                        {this.props.club_id !== this.props.user_id &&
                            <button className="btn btn-primary px-5" onClick={this.challenge}>Challenge</button>
                        }
                    </div>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="panel">
                    <div className="panel-header">
                        <h2>Official Senior Honours</h2>
                    </div>
                    <div className="panel-body">
                        ici
                    </div>
                    </div>
                </div>

            </div>
            
        </div>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,
    club_id: state.ui.club_id,
    user_id: state.user.user_id,
  }
};

export default connect(mapStateToProps)(ClubScreen);