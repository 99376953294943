import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../helpers/auth';
import { setUi } from '../../actions/ui';

class MatchMenu extends React.Component {

  componentDidMount() {
    this.setScreen = this.setScreen.bind(this);
  }

  setScreen(primary, secondary) {
    let payload = {
      title: primary,
      subtitle: secondary,
      primary: primary,
      secondary: secondary
    }
    this.props.dispatch(setUi(payload));
  }

  render() {
    return (
        <ul>
        <li className={this.props.secondary === 'main' ? 'active' : ''}><a onClick={() => this.setScreen('match', 'main')}>Overview</a></li>
        <li className={this.props.secondary === 'matchstats' ? 'active' : ''}><a onClick={() => this.setScreen('match', 'matchstats')}>Match Stats</a></li>
        <li className={this.props.secondary === 'playerstats' ? 'active' : ''}><a onClick={() => this.setScreen('match', 'playerstats')}>Player Stats</a></li>
        <li className={this.props.secondary === 'live' ? 'active' : ''}><a onClick={() => this.setScreen('match', 'live')}>Live Match</a></li>
        <li className={this.props.secondary === 'possession' ? 'active' : ''}><a onClick={() => this.setScreen('match', 'possession')}>Possession</a></li>
        <li className={this.props.secondary === 'tactics' ? 'active' : ''}><a onClick={() => this.setScreen('match', 'tactics')}>Tactics &amp; Subs</a></li>
        <li className={this.props.secondary === 'lineup' ? 'active' : ''}><a onClick={() => this.setScreen('match', 'lineup')}>Line Ups</a></li>
        <li className={this.props.secondary === 'headtohead' ? 'active' : ''}><a onClick={() => this.setScreen('match', 'headtohead')}>Head To Head</a></li>
        <li className={this.props.secondary === 'chat' ? 'active' : ''}><a onClick={() => this.setScreen('match', 'chat')}>Chat</a></li>
        </ul>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,
  }
};

export default connect(mapStateToProps)(MatchMenu);