import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../helpers/auth';
import { setUi } from '../../actions/ui';

class WorldMenu extends React.Component {

  componentDidMount() {
    this.setScreen = this.setScreen.bind(this);
  }

  setScreen(primary, secondary) {
    let payload = {
      title: primary,
      subtitle: secondary,
      primary: primary,
      secondary: secondary
    }
    this.props.dispatch(setUi(payload));
  }

  render() {
    return (
        <ul>
        <li className={this.props.secondary === 'main' ? 'active' : ''}><a onClick={() => this.setScreen('world', 'main')}>Clubs</a></li>
        </ul>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,
  }
};

export default connect(mapStateToProps)(WorldMenu);