import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { auth } from "../../services/firebase";
import { db } from "../../services/firebase";

export default class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser
    };
  }

  render() {
    return (
      <div>
        <Header />

        <main>Main.</main>

        <Footer />
      </div>
    );
  }
}