import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { auth, db, analytics, dbstore } from "../../services/firebase";
import { connect } from 'react-redux';
import Loader from "../../components/Loader";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_RXbB7p3M73mTt0thUB3H4v1b");

class Setup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      name: '',
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async componentDidMount() {
    
  }

  async handleSubmit(event) {
    event.preventDefault();

    var _self = this;
    
    dbstore.collection("clubs").doc(this.state.user.uid).set({
        name: this.state.name
    })
    .then(function() {
        console.log("Club setup saved.");
        _self.props.history.push(`/`);
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });
    
  }

  render() {
    return (
      <div>
        
        {this.state.isLoading ?
        <Loader />
        :
        <div className="billing_wrap">
        <div className="billing">

        <div className="text-center mb-4">
        <form className="mt-5 py-5 px-5" onSubmit={this.handleSubmit}>
            <h1>Setup</h1>
            <p>You need to setup your club to continue.</p>

            <div className="form-group">
                <label for="exampleInputEmail1">Club Name</label>
                <input type="text"
                className="form-control" 
                name="name"
                onChange={this.handleChange}
                value={this.state.name}
                placeholder="Enter name" />
            </div>
            
            <button className="btn btn-primary px-5" type="submit">Submit</button>
        </form>
        </div>

        </div>
        </div>
        }

      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    /*title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,*/
  }
};

export default connect(mapStateToProps)(Setup);