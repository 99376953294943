import React from 'react';
import { version } from '../../../package.json';

function Footer() {
  return (
    <footer>
      <div className="version">{version}</div>
    </footer>
  )
}

export default Footer;