import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { auth, db, analytics, dbstore } from "../../services/firebase";
import { connect } from 'react-redux';
import Loader from "../../components/Loader";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("pk_test_RXbB7p3M73mTt0thUB3H4v1b");

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      subscription_id: '',
      subscription: {},
      price_id: '',
      price: {},
      isLoading: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
      var _self = this;
    dbstore.collection('products')
    .where('active', '==', true)
    .get()
    .then(function (querySnapshot) {
        querySnapshot.forEach(async function (sub) {
            console.log(sub.id, sub.data());
            const priceSnap = await sub.ref.collection('prices').where('active', '==', true).get();
            priceSnap.docs.forEach((price) => {
                console.log(price.id, price.data());
                _self.setState({
                    subscription_id: sub.id,
                    subscription: sub.data(),
                    price_id: price.id,
                    price: price.data(),
                    isLoading: false,
                });
            });
        });
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    
    const stripe = await stripePromise;

    const docRef = await dbstore
        .collection('customers')
        .doc(this.state.user.uid)
        .collection('checkout_sessions')
        .add({
            price: this.state.price_id,
            allow_promotion_codes: true,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        });

        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
            
            const { sessionId } = snap.data();
            if (sessionId) {
                // We have a session, let's redirect to Checkout
                // Init Stripe
                stripe.redirectToCheckout({ sessionId });
            }

        });

  }

  render() {
    return (
      <div>
        
        {this.state.isLoading ?
        <Loader />
        :
        <div className="billing_wrap">
        <div className="billing">

        <div className="text-center mb-4">
        <form className="mt-5 py-5 px-5" onSubmit={this.handleSubmit}>
            <h1>Billing</h1>
            <p>You don't have an active subscription.</p>
            <h2>{this.state.subscription.name}</h2>
            <p>{this.state.subscription.description}</p>
            
            <button className="btn btn-primary px-5" type="submit">&pound;{this.state.price.unit_amount / 100}</button>
        </form>
        </div>

        </div>
        </div>
        }

      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    /*title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,*/
  }
};

export default connect(mapStateToProps)(Billing);