import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyBBlHttOP2BP5pHKG_rAyCvpLSxtUxc2JE",
    authDomain: "tmlive-42e82.firebaseapp.com",
    databaseURL: "https://tmlive-42e82.firebaseio.com",
    projectId: "tmlive-42e82",
    storageBucket: "tmlive-42e82.appspot.com",
    messagingSenderId: "560667457182",
    appId: "1:560667457182:web:870db7c4b22b93da2eed8f",
    measurementId: "G-PEKMSPFR72"
};
firebase.initializeApp(config);
export const auth = firebase.auth;
export const db = firebase.database();
export const analytics = firebase.analytics();
export const dbstore = firebase.firestore();