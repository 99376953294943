import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';

export default class SplashPage extends Component {
  render() {
    return (
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <header className="masthead mb-auto">
          <div className="inner">
            <h3 className="masthead-brand">TML</h3>
            <nav className="nav nav-masthead justify-content-center">
              <a className="nav-link active" href="#">Home</a>
              <a className="nav-link" href="#">Features</a>
              <a className="nav-link" href="#">Contact</a>
            </nav>
          </div>
        </header>

        <main role="main" className="inner cover">
          <h1 className="cover-heading"><img src="/assets/images/tml.png" /></h1>
          <p className="lead">
            <Link className="btn btn-lg btn-primary" to="/signup">Sign Up</Link>
            <Link className="btn btn-lg btn-secondary" to="/login">Sign In</Link>
          </p>
        </main>

        <footer className="mastfoot mt-auto">
          <div className="inner">
            <p>Copyright 2020 <a href="#">TML</a>.</p>
          </div>
        </footer>

      </div>

    )
  }
}