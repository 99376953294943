import React from 'react';
import ReactDOM from 'react-dom';
import "./scss/tml.scss";
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import configureStore from './store/configure-store';
import {loadStripe} from '@stripe/stripe-js';

const store = configureStore();
const stripePromise = loadStripe("pk_test_RXbB7p3M73mTt0thUB3H4v1b");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
