import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { auth } from '../services/firebase';
import { connect } from 'react-redux';
import ManagerScreen from '../screens/Manager';
import ChatScreen from '../screens/Chat';
import WorldScreen from '../screens/World';
import ClubScreen from '../screens/Club';
import NewsScreen from '../screens/News';
import SquadScreen from '../screens/Squad';
import MatchLiveScreen from '../screens/MatchLive';
import ProfileScreen from '../screens/Profile'
;
class Screens extends React.Component {

    constructor(props) {
      super(props);

    }
   
    render() {
        return (
            <div>
                {this.props.primary === 'news' && this.props.secondary === 'main' &&
                    <NewsScreen {...this.props} />
                }
                {this.props.primary === 'manager' && this.props.secondary === 'main' &&
                    <ManagerScreen />
                }
                {this.props.primary === 'world' && this.props.secondary === 'main' &&
                    <WorldScreen />
                }
                {this.props.primary === 'chat' && this.props.secondary === 'main' &&
                    <ChatScreen />
                }
                {this.props.primary === 'club' && this.props.secondary === 'main' &&
                    <ClubScreen />
                }
                {this.props.primary === 'club' && this.props.secondary === 'squad' &&
                    <SquadScreen />
                }
                {this.props.primary === 'match' && this.props.secondary === 'live' &&
                    <MatchLiveScreen {...this.props} />
                }
                {this.props.primary === 'profile' &&
                    <ProfileScreen {...this.props} />
                }
            </div>
        )
    }

};

const mapStateToProps = (state) => {
    return {
      title: state.ui.title,
      subtitle: state.ui.subtitle,
      primary: state.ui.primary,
      secondary: state.ui.secondary,
    }
  };
  
  export default connect(mapStateToProps)(Screens);