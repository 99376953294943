import { auth, db, analytics, dbstore } from "../services/firebase";

class ClubAPI {

    getClubs(cb) {
        dbstore.collection("clubs").get().then(function(snapshot) {
            let items = [];
            snapshot.forEach(function(snap) {
                let item = snap.data();
                item.id = snap.id;
                items.push(item);
            });
            cb(items);
        }).catch(function(error) {
            console.log("Error getting document:", error);
            cb([]);
        });
    }

    getClub(id, cb) {
        var docRef = dbstore.collection("clubs").doc(id);
        docRef.get().then(function(doc) {
        if (doc.exists) {
            cb(doc.data());
        } else {
            console.log("No club setup");
            cb(null);
        }
        }).catch(function(error) {
            console.log("Error getting document:", error);
            cb(null);
        });
    }

}

export default new ClubAPI();