import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../helpers/auth';
import { setUi } from '../../actions/ui';

class ManagerScreen extends React.Component {

  componentDidMount() {
      
  }

  render() {
    return (
        <div className="screen">
            Manager.
        </div>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,
  }
};

export default connect(mapStateToProps)(ManagerScreen);