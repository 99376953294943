import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../helpers/auth';
import { setUi } from '../../actions/ui';
import { auth, db, analytics, dbstore } from "../../services/firebase";
import ClubAPI from '../../api/ClubAPI';

class WorldScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          clubs: []
        };

        this.setClub = this.setClub.bind(this);
    }

  componentDidMount() {
      this.loadClubs();
  }

  setClub(id, club) {
    let payload = {
      title: club,
      subtitle: id,
      primary: 'club',
      secondary: 'main',
      club_id: id,
    }
    this.props.dispatch(setUi(payload));
  }

  loadClubs() {
    ClubAPI.getClubs((data) => {
        this.setState({
            clubs: data
        });
    });
  }

  render() {
    return (
        <div className="screen">
            <div className="panel full">
                <div className="panel-header">
                    <h2>Clubs</h2>
                </div>
                <div className="panel-body">
                    <table>
                        <thead>
                            <tr>
                                <th>Club Name</th>
                                <th>Manager</th>
                                <th>Joined</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.clubs.map((item, index) =>
                            <tr key={index}>
                                <td><a onClick={() => this.setClub(item.id, item.name)}>{item.name}</a></td>
                                <td>XX</td>
                                <td>XX</td>
                                <td>XX</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,
  }
};

export default connect(mapStateToProps)(WorldScreen);