import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { auth, db, analytics, dbstore } from "../../services/firebase";
import { connect } from 'react-redux';
import Screens from "../../config/screens";
import { setUser } from '../../actions/user';
import { setUi } from '../../actions/ui';

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      club: {},
    };
    
    analytics.logEvent('Game interface loaded.');

  }

  checkSub() {
    dbstore.collection('customers')
    .doc(this.state.user.uid)
    .collection('subscriptions')
    .where('status', 'in', ['trialing', 'active'])
    .onSnapshot(async (snapshot) => {

      console.log(snapshot.docs);

      if (snapshot.docs.length === 0) {
        console.log("No subscription, redirect user to billing");
        this.props.history.push(`/billing`);
      }

      // In this implementation we only expect one active or trialing subscription to exist.
      //const doc = snapshot.docs[0];
      //console.log(doc.id, ' => ', doc.data());
    });
  }

  checkActiveMatch() {
    var _self = this;
    dbstore.collection('matches')
    .where('started', '==', true)
    .where('completed', '==', false)
    .onSnapshot(async (snapshot) => {

      if (snapshot.docs.length > 0) {
        const doc = snapshot.docs[0];
        console.log("Match in progress, load now...", doc);
        let payload = {
          match_id: doc.id,
        }
        _self.props.dispatch(setUi(payload));
      }

      // In this implementation we only expect one active or trialing subscription to exist.
      //const doc = snapshot.docs[0];
      //console.log(doc.id, ' => ', doc.data());
    });
  }

  checkSetup() {
    var _self = this;
    var docRef = dbstore.collection("clubs").doc(this.state.user.uid);
    docRef.get().then(function(doc) {
      if (doc.exists) {

        let payload = {
          user_id: _self.state.user.uid,
          club_name: doc.data().name
        }

        console.log("setUser()", payload);
        _self.props.dispatch(setUser(payload));

        console.log("Club data:", doc.data());
        _self.setState({
          club: doc.data()
        });
      } else {
        console.log("No club setup");
        _self.props.history.push(`/setup`);
      }
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  }

  async componentDidMount() {

    this.checkSub();
    this.checkSetup();
    this.checkActiveMatch();

  }

  render() {
    return (
      <div>
        <Header {...this.props} club={this.state.club} />

        <Screens {...this.props} />

        <Footer />

      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,
    user_id: state.user.user_id,
  }
};

export default connect(mapStateToProps)(Game);