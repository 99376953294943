import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../helpers/auth';
import { setUi } from '../../actions/ui';
import { auth, db, analytics, dbstore } from "../../services/firebase";
import ClubAPI from '../../api/ClubAPI';

class NewsScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          clubs: []
        };
    }

  componentDidMount() {
      this.loadClubs();
  }

  loadClubs() {
    ClubAPI.getClubs((data) => {
        this.setState({
            clubs: data
        });
    });
  }

  render() {
    return (
        <div className="screen">
            <div className="row">

                <div className="col-sm-6">
                    <div className="panel full">
                    <div className="panel-header">
                        <h2>Latest News</h2>
                    </div>
                    <div className="panel-body">
                        ici
                    </div>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="panel full">
                    <div className="panel-header">
                        <h2>Subject</h2>
                    </div>
                    <div className="panel-body">
                        ici
                    </div>
                    </div>
                </div>

            </div>
            
        </div>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,
    club_id: state.ui.club_id,
  }
};

export default connect(mapStateToProps)(NewsScreen);