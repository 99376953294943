import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../helpers/auth';
import { setUi } from '../../actions/ui';
import { NewsMenu, ManagerMenu, WorldMenu, ClubMenu, MatchMenu } from '../Menu';
import { auth } from "../../services/firebase";
import News from '../../pages/News';

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
    };

    console.log("user()", auth().currentUser);

  }

  componentDidMount() {
    this.setScreen = this.setScreen.bind(this);
  }

  setScreen(primary, secondary) {
    let payload = {
      title: primary,
      subtitle: secondary,
      primary: primary,
      secondary: secondary
    }
    this.props.dispatch(setUi(payload));
  }

  render() {
    return (
      <header>
        <nav className="main">
          <ul>
            <li className={this.props.primary === 'news' ? 'active' : ''}><a onClick={() => this.setScreen('news', 'main')}>News</a></li>
            <li className={this.props.primary === 'manager' ? 'active' : ''}><a onClick={() => this.setScreen('manager', 'main')}>Manager</a></li>
            <li className={this.props.primary === 'club' ? 'active' : ''}><a onClick={() => this.setScreen('club', 'main')}>Club</a></li>
            <li className={this.props.primary === 'competitions' ? 'active' : ''}><a onClick={() => this.setScreen('competitions', 'main')}>Competitions</a></li>
            <li className={this.props.primary === 'search' ? 'active' : ''}><a onClick={() => this.setScreen('search', 'main')}>Search</a></li>
            <li className={this.props.primary === 'world' ? 'active' : ''}><a onClick={() => this.setScreen('world', 'main')}>World</a></li>
            {this.props.match_id !== '' && <li className={this.props.primary === 'match' ? 'active' : 'match-tab'}><a onClick={() => this.setScreen('match', 'live')}>Go to Match</a></li>}
          </ul>
          <ul className="header-controls">
            <li><button className="user" onClick={() => this.setScreen('profile', 'main')}><i className="fas fa-user"></i></button></li>
            <li><button className="chat" onClick={() => this.setScreen('chat', 'main')}><i className="fas fa-comment"></i></button></li>
            <li><button className="settings" onClick={() => this.setScreen('chat', 'main')}><i className="fas fa-wrench"></i></button></li>
            <li><button className="logout" onClick={() => logout()}><i className="fas fa-sign-out-alt"></i></button></li>
          </ul>
        </nav>
        <div className="title">
          <h1>{this.props.title}</h1>
          <h2>{this.props.subtitle}</h2>
          </div>
        <nav className="sub">
          {this.props.primary === 'news' &&
            <NewsMenu />
          }
          {this.props.primary === 'manager' &&
            <ManagerMenu />
          }
          {this.props.primary === 'world' &&
            <WorldMenu />
          }
          {this.props.primary === 'club' &&
            <ClubMenu />
          }
          {this.props.primary === 'match' &&
            <MatchMenu />
          }
        </nav>
      </header>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,
    match_id: state.ui.match_id,
  }
};

export default connect(mapStateToProps)(Header);