import {
    GET_UI,
    SET_UI,
} from '../actions/ui';

const initialState = {
    title: 'News',
    subtitle: 'Latest News',
    primary: 'news',
    secondary: 'main',
    club_id: '',
    player_id: '',
    match_id: '',
};

const ui = (state = initialState, action) => {
    switch (action.type) {
        case GET_UI:
            return {
                ...state,
            };
        case SET_UI:
            return {
                ...state,
                title: action.payload.title ? action.payload.title : state.title,
                subtitle: action.payload.subtitle ? action.payload.subtitle : state.subtitle,
                primary: action.payload.primary ? action.payload.primary : state.primary,
                secondary: action.payload.secondary ? action.payload.secondary : state.secondary,
                club_id: action.payload.club_id ? action.payload.club_id : state.club_id,
                match_id: action.payload.match_id ? action.payload.match_id : state.match_id,
            };
        default:
            return state
    }
}

export default ui