import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../helpers/auth';
import { setUi } from '../../actions/ui';
import { auth, db, analytics, dbstore } from "../../services/firebase";
import ClubAPI from '../../api/ClubAPI';

class ProfileScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
    };

    console.log("user()", auth().currentUser);

  }

  render() {
    return (
        <div className="screen">
            <div className="row">

                <div className="col-sm-12">
                    <div className="panel">
                    <div className="panel-header">
                        <h2>Profile</h2>
                    </div>
                    <div className="panel-body">
                          
                        <div style={{color: '#fff'}}>Club Name: {this.props.club_name}</div>
                        <div style={{color: '#fff'}}>User ID: {this.props.user_id}</div>
                        <div style={{color: '#fff'}}>User Email: {this.state.user.email}</div>
                    </div>
                    </div>
                </div>

            </div>
            
        </div>
    )
  }

};

const mapStateToProps = (state) => {
  return {
    title: state.ui.title,
    subtitle: state.ui.subtitle,
    primary: state.ui.primary,
    secondary: state.ui.secondary,
    club_id: state.ui.club_id,
    user_id: state.user.user_id,
    club_name: state.user.club_name,
  }
};

export default connect(mapStateToProps)(ProfileScreen);