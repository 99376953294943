import {
    GET_USER,
    SET_USER,
} from '../actions/user';

const initialState = {
    user_id: '',
    club_name: '',
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
            };
        case SET_USER:
            return {
                ...state,
                user_id: action.payload.user_id ? action.payload.user_id : state.user_id,
                club_name: action.payload.club_name ? action.payload.club_name : state.club_name,
            };
        default:
            return state
    }
}

export default user