export const GET_UI = 'GET_UI';
export const SET_UI = 'SET_UI';

export const getUi = () => ({
    type: GET_UI,
});

export const setUi = (payload) => ({
    type: SET_UI,
    payload,
});

